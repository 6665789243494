@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "react-circular-progressbar/dist/styles.css";

@import "react-toastify/dist/ReactToastify.css";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@layer base {
  img {
    @apply inline-block;
  }
}
@layer components {
  .container2 {
    @apply mx-auto w-[93%] lg:w-[95%] lg:max-w-[1368px];
  }
  .container {
    @apply lg:w-full lg:px-12 xl:w-[95%] mg:w-[1824px];
  }
  .container-full {
    @apply mx-auto w-full px-4 xl:px-12;
  }

  .character-selected-verification-input {
    position: relative;
  }
  .select-container {
    border-radius: 12px;
  }

  .action-suggestions-box {
    -webkit-mask-image: linear-gradient(to bottom, black 60%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 60%, transparent 100%);
  }

  .character-selected-verification-input:empty::after {
    content: "";
    width: 1px;
    height: 24px;
    background-color: #333;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: blink 1s infinite step-end;
  }

  .sciences .swiper-wrapper {
    @apply flex w-full items-center pb-8 lg:h-[311px];
  }
  .sciences .swiper-slide {
    @apply flex transition-all max-lg:h-[235px] max-lg:w-[300px] lg:h-[260px] lg:w-[380px];
  }
  .sciences .swiper-slide-active {
    @apply lg:h-[311px] lg:w-[559px];
  }

  .preferences {
    @apply lg:hidden;
  }

  /* BACKGROUNDS */

  .graph-bg {
    background-size: 22px 22px;
    background-position: -2px -2px;
    background-image: linear-gradient(to right, #eae3f890 1px, transparent 1px),
      linear-gradient(to bottom, #eae3f890 1px, transparent 1px);
  }

  .cnaRoadmapBg {
    background:
      url("../src/assets/bg/bgGradientCloud2.png") 200% -0% no-repeat,
      url("../src/assets/bg/bgGradientCloud2.png") -200px -130px no-repeat;
  }

  .gradient-text {
    background: linear-gradient(to right, #e11bd4, #5c38e4, #e11bd4, #5c38e4);
    background-size: 300% 300%;
    background-clip: text;
  }
  .gradient-border-wrapper {
    position: relative;
    border-radius: var(--border-radius); /* Adjust to desired rounding */
  }

  .gradient-border-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: var(--border-radius);
    padding: var(--padding); /* Adjust to control the border width */
    background: linear-gradient(75deg, var(--gradient-start), var(--gradient-end), var(--gradient-start));
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  .gradient-border-content {
    flex: inherit;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: inherit; /* Match the parent's border-radius */
    background-color: transparent; /* Ensure the background is transparent */
    z-index: 1; /* Ensure content is above the pseudo-element */
    padding: 1px;
  }
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  /* INPUTS */

  /* Hide the native increment/decrement buttons of number input */
  .input-number::-webkit-outer-spin-button,
  .input-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .input-number {
    -moz-appearance: textfield; /* Firefox */
  }

  .range-input {
    height: 12px;
    width: 100%;
    cursor: pointer;
    appearance: none;
    border-radius: 8px;
    /* background: linear-gradient(
      to right,
      #ff0000 0%,
      #ff0000 50%,
      #ccc 55%,
      #ccc 100%
    ); */

    outline: none;
  }
  .range-input::-webkit-slider-runnable-track {
    appearance: none;
    -webkit-appearance: none;
  }
  /* .range-input::-webkit-slider-runnable-track::after {
    appearance: none;
    -webkit-appearance: none;
    content: "";
    display: block;
    position: absolute;
    background-color: #e11bd4;
    left: 0;
    top: 0;
    height: 6px;
    width: 40px;
  } */
  .range-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 34px;
    width: 34px;
    background: #fff;
    border-radius: 50%;
    border: 4px black solid;
  }
  .phone-input {
    @media (min-width: 1080px) {
      outline: none;
      height: 100%;
      width: 100%;
      font-size: 17px;
    }
    font-size: 13px;
    outline: none;
  }
  .phone-input-dropdown {
    @media (min-width: 1080px) {
      top: 60px;
      width: 388px;
    }
    top: 50px;
    width: 312px;
    outline: none;
  }
  .input-text {
    -webkit-border-radius: 12px;
    border-radius: 12px;
  }
  .input-phone {
    -webkit-border-radius: 12px;
    border-radius: 12px;
  }

  .wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 500px;
    overflow: hidden;
    line-height: 0;
    z-index: 0;
  }

  /* CIRCULAR PROGRESS */
  .progress-ring__circle {
    stroke-dasharray: 400, 400;
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }

  .input-text:not(:placeholder-shown) ~ .input-text-placeholder,
  .input-text:-webkit-autofill ~ .input-text-placeholder {
    @apply -top-[6px] left-6 px-2.5 text-[12px] lg:-top-[7px] lg:text-[13px];
  }

  .input-text:not(:placeholder-shown) {
    /* @apply outline-primary-600; */
  }
  .input-phone:not(:placeholder-shown) ~ .input-phone-placeholder {
    @apply -top-[6px] left-6 px-2.5 text-[12px] lg:-top-[7px] lg:text-[13px];
  }

  .input-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .input-checkbox:checked ~ .input-checkmark:after {
    display: block;
  }
  .input-checkbox:checked ~ .input-checkmark:after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* SHAPES */

  .trapezoid-container {
    position: relative;
    min-width: 120px;
    border-top-left-radius: 12px; /* Rounded top-left corner */
  }

  .trapezoid-container::before {
    content: "";
    position: absolute;
    top: 0;
    right: -18px;
    width: 25px;
    height: 100%;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.3);
    border-top-right-radius: 12px; /* Rounded top-right corner */
    background-color: white;
    background-color: inherit; /* Inherit background color from the parent */
    transform: skewX(20deg);
    z-index: -1;
  }

  /* using borders */
  .trapezoidLeft {
    border-bottom: 10px solid #2f9c95;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    position: absolute;
    right: -5px;
    top: -2.5px;
    transform: translateY(50%);
  }
  .trapezoidRigth {
    border-bottom: 10px solid #2f9c95;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    position: absolute;
    left: 5px;
    top: -2.5px;
    transform: translateY(50%);
  }
  .indicatorLeft {
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 20px solid #266a93;
    position: absolute;
    left: -14px;
    top: -5px;
  }
  .indicatorRigth {
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 20px solid #266a93;
    position: absolute;
    right: -14px;
    top: -5px;
  }
  /* &&&&&&&&& */
  /* CARD PILE */
  /* &&&&&&&&& */
  .mobile-form {
  }

  #deck {
    position: relative;
    list-style: none;
    height: 430px;
  }

  #deck .card {
    display: block;
    position: absolute;
    width: 100%;
    height: 376px;
    top: 0;
    left: 0;
    z-index: 3;
    opacity: 1;
  }

  #deck .card:first-child {
    opacity: 1;
  }
  #deck .card:nth-child(2) {
    opacity: 1;
    transform: scaleX(0.96);
    top: 12px;
    left: 0;
    z-index: 2;
  }
  #deck .card:nth-child(3) {
    opacity: 1;
    top: 24px;
    transform: scaleX(0.93);
    left: 0px;
    z-index: 1;
  }
  #deck .card:nth-child(4) {
    opacity: 1;
    top: 36px;
    transform: scaleX(0.9);
    left: 0px;
    z-index: 1;
  }
  #deck .card:nth-child(n + 4) {
    opacity: 0;
    z-index: 0;
    transform: scaleX(0);
  }

  #deck.sliding .card {
    transition:
      transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
      opacity 0.3s ease-in-out;
  }
  #deck.sliding .card:nth-child(1) {
    transform: translate(100px, 0);
    opacity: 0;
  }
  #deck.sliding .card:nth-child(2) {
    transform: translate(0, -12px) scaleX(1);
    opacity: 1;
  }
  #deck.sliding .card:nth-child(3) {
    opacity: 0.7;
    transform: translate(0, -12px) scaleX(0.96);
  }
  #deck.sliding .card:nth-child(4) {
    opacity: 0.5;
    transform: translate(0, -12px) scaleX(0.93);
  }
  /* &&&&&&&&&&&&&&&&&&&&&&&&&&& */
  /* SLIDING COMPANY LOGO BANNER */
  /* &&&&&&&&&&&&&&&&&&&&&&&&&&& */
  .scroller {
  }
  .scroller[data-animated="true"] {
    overflow: hidden;
    mask: linear-gradient(90deg, transparent, white 15%, white 85%, transparent);
    -webkit-mask: linear-gradient(90deg, transparent, white 15%, white 85%, transparent);
  }
  .scroller[data-animated="true"] .scroller__inner {
    animation: scroll 30s linear infinite;
  }
  @keyframes scroll {
    to {
      transform: translate(calc(-50% - 1rem));
    }
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
